<template>
    <div>
        <v-container>
            <v-layout row wrap>
                <v-flex xs12 md10>
                    <donutChart style="height: 200px;" :value="tableData"></donutChart>
                    <v-layout row wrap>
                        <v-chip color="red" large>
                            <v-card flat class="d-flex align-start flex-column transparentBackground" style="padding: 0 8px;">
                                <v-card flat class="transparentBackground">Spent:</v-card>
                                <v-card flat class="transparentBackground">{{asMoney(tableData[0].value)}}</v-card>
                            </v-card>
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-chip color="green" large>
                            <v-card flat class="d-flex align-start flex-column transparentBackground" style="padding: 0 8px;">
                                <v-card flat class="transparentBackground">Remaining:</v-card>
                                <v-card flat class="transparentBackground">{{asMoney(tableData[1].value)}}</v-card>
                            </v-card>
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-chip color="blue" large>
                            <v-card flat class="d-flex align-start flex-column transparentBackground" style="padding: 0 8px;">
                                <v-card flat class="transparentBackground">Total:</v-card>
                                <v-card flat class="transparentBackground">{{asMoney(tableTotal)}}</v-card>
                            </v-card>
                        </v-chip>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md2>
                    <v-layout row wrap style="padding-top: 15px;">
                        <v-flex xs6 md12 v-if="budget.budgetType !== 'None'"><v-chip right>Type: {{budget.budgetType}} </v-chip></v-flex>
                        <v-flex xs6 md12><v-chip>Account: {{budget.account}} </v-chip></v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout row wrap> 


                <!--<v-flex xs12>Category: {{ budget.category }}</v-flex>-->
                <!--<template v-if="budget.Type === 'OneTime'">
                <v-flex xs12 sm6> Date: {{ budget.Date }}</v-flex>
                <v-flex xs12 sm6>Comment: {{ budget.comment }}</v-flex>
            </template>
            <template v-if="budget.Type === 'Recurring'">
                <v-flex xs12 sm6>Repeat Every {{ budget.repeat }} {{ budget.cycle }} &nbsp; <span v-if="budget.period === 'Forever'"> {{ budget.period }} </span><span v-if="budget.period !== 'Forever'">For {{ budget.numberOfCycles }} {{ budget.cycle }}s</span> </v-flex>
                <v-flex xs12 sm6>Goes Off First On: {{ budget.goesOffFirst }}</v-flex>
                <v-flex xs12 sm6>Comment: {{ budget.comment }}</v-flex>
            </template>-->

            </v-layout>

        </v-container>

        <v-divider></v-divider>
        <!--<v-container>
            <widgetContainer v-bind:widgetData="{link: transactionsLink, title: 'Budget Transactions'}"></widgetContainer>
        </v-container>-->

    </div>
</template>

  <script>
      import { globalFunctions } from '../../javascript/globalFunctions'
      import { widgetManager } from '../../javascript/widgetManager'
      import { budgetManager } from '../../javascript/budgetManager'

      export default {
          components: {
              donutChart: () => import('../../components/charts/donutChartBudget.vue'),
              widgetContainer: () => import('../../components/widgets/widgetContainer.vue')
          },
      props: ['budgetId'],
      data() {
        return {
            budget: [],
            tableData: [],
            tableTotal: 0,
            transactionsLink: './budgetWidget/bWMComponents/budgetTransactionsSingle.vue'
        }
      },
      computed: {
          asMoney: function () {
              return (amount) => {
                  return globalFunctions.formatMoney(amount, 2, ".", ",")
              }
          }
      },
      beforeMount() {
          let table = widgetManager.buildSingleBudgetTotals(this.budgetId, new Date())
          this.tableData = table.tableData
          this.tableTotal = table.tableTotal

          this.budget = budgetManager.findBudgetById(this.budgetId)
      }
    }
  </script>
